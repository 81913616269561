export const BASE_URL = process.env.REACT_APP_API_DOMAIN

export const WEB_ROUTES = {
    LOGIN: 'login',
    OTP_VERIFICATION: 'otp-verification',
    FORGOT_PASSWORD: "forgot-password",
    DASHBOARD: 'dashboard',
    PM_MANAGEMENT: 'project-manager',
    AM_MANAGEMENT: 'account-manager',
    LOOKUPS: 'lookups'

}

export const BREAKPOINTS = {
    MOBILE: 991
}
export const ROLES = {
    VIEWER: 'VIEWER',
    COACH: 'COACH',
    PLAYER: 'PLAYER',
}

export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/*'

export const PASSWORD_PATTERN = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/