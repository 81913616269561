import React from "react";

/**Import Styles and Assets */
import { ReactComponent as DashBoard1 } from "./images/dashboard1.svg";
import styles from "./index.module.scss";


/**Import From Antd */
import { Col, Row, Spinner } from 'react-bootstrap';

export default function DashBoard() {
    const data = {
        user_count: 10,

    }
    const item = [
        {
            image: <DashBoard1 />,
            number: data?.user_count ? data.user_count : 0,
            info: "Number of Project Manager",
            color1: "#BA2D52",
            color2: "#D93B30"

        },
        {
            image: <DashBoard1 />,
            number: data?.user_count ? data.user_count : 0,
            info: "Number of Account Manager",
            color1: "#BA2D52",
            color2: "#D93B30"

        },
        // {
        //     image: <DashBoard2 />,
        //     number: data?.sound_track_count ? data.sound_track_count : 0,
        //     info: "Number of Sound Tracks",
        //     color1: "#C33764",
        //     color2: "#1D2671"

        // },

    ]
    return (
        <div className={styles.dashBoard}>
            <div className={styles.content}>
                <Row gutter={[16, 16]} className={styles.row}>
                    {item.map((i, index) => {
                        return <Card {...{ ...i }} index={index} style={(index === 4) ? { width: "44px", height: "44px" } : {}} />
                    })}
                </Row>


            </div>

        </div>

    )
}

export function Card({ image, number, info, color1, color2, status }) {
    return (
        <Col lg={4} sm={6} xs={12} >
            <div className={styles.card} style={{ backgroundImage: `linear-gradient(180deg, ${color1}, ${color2})` }}>
                <div className={styles.imageBack}>
                    {image}
                    {/* <img src={image} style={style} alt={`Dash Board${index} Image`} /> */}
                </div>
                <h1>{status === "loading" ? <Spinner size="lg" /> : number}</h1>
                <h4>{info}</h4>

            </div>
        </Col>
    )
}