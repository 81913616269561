import _ from "lodash"

import Table from "react-bootstrap/Table"

import styles from "./index.module.scss"

export default function TableLayout({ header = [], data = [] }) {

    return (
        <Table responsive="lg" className={styles.Table}>
            <thead className={styles.thead}>
                <tr className={styles.trh}>
                    {header?.map((item, index) => {
                        return <th
                            key={index}
                            className={styles.th}
                            style={item.style || {}}
                        >
                            {item.title}
                        </th>
                    })}
                </tr>
            </thead>
            <tbody className={styles.tbody}>
                {data?.map((item, index) => {
                    return <tr className={styles.trd} key={index}>
                        {
                            header?.map((col, index) => {
                                return (
                                    <td className={styles.td}>
                                        {
                                            _.isFunction(col?.render) ?
                                                col.render(item, index, data) :
                                                item[col.dataIndex] || ''
                                        }
                                    </td>
                                )
                            })
                        }
                    </tr>
                })}
            </tbody>
        </Table>
    )
}