import React, { useCallback, useState } from "react";

/**Import Styles anad Assets */
import styles from "./index.module.scss";

/**Import Custom Components */
import ContentContainer from "../../Components/Container/ContentContainer";
// import EditLookup from "./EditLookup";

/**Import Packaged Components */
import { AiFillEdit } from "react-icons/ai";

export default function Lookups() {
    const [editLookup, setEditLookup] = useState(null)

    const handleModalClose = useCallback(() => {
        setEditLookup(null);
    }, [])

    const [data, setData] = useState([
        {
            title: "Test",
            values: ['Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test',
                'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test'],
        },
        {
            title: "Test",
            values: ['Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test',
                'Test', 'Test', 'Test', 'Test', 'Test', 'Test', 'Test'],
        },
    ])

    // {/* {editLookup ? <EditLookup lookupData={editLookup} handleClose={handleModalClose} /> : ''} */ }

    return (
        <div className={styles.lookupsContainer} >
            {
                data.map((item, index) => {
                    return <div
                        key={item.key}
                        className={`${styles.lookup} ${(index != (data.length - 1)) ? styles.bottomMargin : ''}`}
                    >
                        <div className={styles.headerContainer}>
                            <h1 className={styles.title}>{item.title}</h1>
                            <span
                                onClick={() => setEditLookup(item)}
                            >
                                <AiFillEdit />
                            </span>
                        </div>
                        <div className={styles.tagsContainer}>
                            {(item.values).map((tag, index) => {
                                return <p
                                    className={styles.tag}
                                    key={`tag_${index}`}
                                >
                                    {tag}
                                </p>
                            })}

                        </div>
                    </div>
                })
            }
        </div>
    )
}