import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Description, Input, Title } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { Toast } from "../../Hooks/useToast";
import { useAppContext } from "../../Context/AppContext";


export default function Login({ setPage }) {
    const navigate = useNavigate();
    const { updateUser } = useAppContext()

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const handleForgotPasswordClick = () => {
        navigate("/" + WEB_ROUTES.FORGOT_PASSWORD)
    }

    // const handleSignUpClick = () => {
    //     navigate("/" + WEB_ROUTES.SIGN_UP)
    // }

    const handleChange = (name, value) => {
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleSubmit = async () => {
        if (isSubmitted) return;
        if (!data.email) return Toast('Email is required', 'error', false)
        if (!data.password) return Toast('Password is required', 'error', false)
        try {
            setIsSubmitted(true);
            Toast('Validating credentials', 'loading', true)
            // const response = await postUser(data).unwrap();
            // dispatch(setUser(response))
            updateUser({
                role: "admin",
                name: "Test User",
                image_url: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKsAAACUCAMAAADbGilTAAAAMFBMVEXk5ueutLenrrHg4+Tn6eqqsLTN0NLT1tjY29zGysy4vcC/xMbJzc/b3t/q7O2xt7pnBWjmAAAD/klEQVR4nO2cW7LbIAxADQiDIcD+d1ucx62TJr6AbIlMOR+dfp4qAiSQO02DwWAwGAwGg8FgMBicAQC3QQkxRmWNMTaoGHs2VmH2Qkqptc5/yuRNyDHmtvoXAHtxQost2dnPtreEgGhdehZ9+CYfIrfeE8G9Fb1H16luQgtqkZ9Vr7pLJ7ZgdoL6kHW2B1lYfhO9MfPLKv9rUH/ygFvVlZmusp45suWqqyynaUVUb7KMaeCrVNec5UqDuBQuqw2GRxasrFYVwrK4qtSgKhxLyhaeAS/oC0MW2PpkvSLpAwvvS8ACyI8EMK2qIpGXMXWnwHNgaU3BtKsKEWhla0+sLcRbQcCEVWjKBgzm5pW1IilPWkCsrBVPGFhoqQQ2JDrVqalq2UK3xUJpj/URukYxItNVCLqau60a3ELXzAS0K1kVCxarKhLVMYsrBm5QtTIwo1U1mesF7Sq/yFWb4XqGK10OfNPa+qY9C+9KdhZ80xmLr100nSug47pQqeZaG+tKV2sjLojuEF4TfVNviE1Y0p4bd5ehSV8NkHdEtJeaqLs32pcjXPlC/RaDWF3UT52YLZb8Pa5926J/7gbTeB4wvBm1bgV64Zgqsl/0xtmWBVQN7Aux4RqWb0Kn9h6Wc+SlsvHSjs00o6pcWVUnqGgT+ccK96Yen1U9t2qmTFbTta47wFyQB8n0MQQLwe+fClr7wP/7PzBiJxFk4hl0+gCAeZ8I+Z/gmObHPgOTWZLWr8PlbjG9zZavwBTWUfjryP51al/7i1U9zuzfyUG0Zs6YoHoM6AZYiSvXv/Upmw1zDoQ1psuNHNr1SxOIXSlff/fFpbc7QUo5a3v4mAdABTv7JPe+L1iXmcj7QWBcZxCVufi0dwpshXX2nQNLgPPu7+ubw+TmibgwAGX97u++E2Dp5kCWDNevnzTiAlYLb0hsP3/9VGWbc+Fs23zqo0UfXE691cimDjtF9hed5vOq2mjR71ovuMs5exioo01XzijDYcIPDbxFHt7e5pbqqCX1ij64b4gnBfXOgbdcOVOPW/3v0IeN8UPx1Uq77EF3nQdMD5bYHvE+d8DQUJks/tXrgFmsUlmHrBBO3gCeZXGRRb6918pi7pKpcvVHtj2yNDvAs2xjygJuoKGNxs961OlHwDvaioMzSsDfSQ3dAn4GqxFX35MrJlWh60sDngxYqZ03pd+uNtS+LqG/zkGgqwLLtrBuVC2viB5wRSEr2kVo/Vb7KCqOWuynj2gqtgL87DiWuTisxKXgG4qLw1Mug+oo3rbqBkPOcS09aNFfvx5A4Rbb8l+hHI4sdGXfBUTxhGTsIAWKx897cC1svLgP2BtFxyzMsgdcUVxVUD1Qojr43/gDTjs3mRf1UggAAAAASUVORK5CYII="
            })
            Toast('User logged in successfully', 'success', false)
        }
        catch (err) {
            if (err.code === 428) {
                navigate("/" + WEB_ROUTES.OTP_VERIFICATION)
                Toast('Verify your email to continue login', 'error', false)
            }
            else {
                Toast(err.message, 'error', false)
            }
            setIsSubmitted(false)
        }
    }

    return (
        <AccountLayout>
            <Description text="Hello there," />
            <Title text="Welcome back" classes="mb-2" />
            <Input
                placeholder="Email Address"
                name="email"
                value={data.email}
                onChange={handleChange}
            />
            <Input
                classes="mt-2 mb-2"
                placeholder="Password"
                type="password"
                name="password"
                value={data.password}
                onChange={handleChange}
            />
            <Description
                classes="ms-auto cursor-pointer"
                text="Forgot Password ?"
                onClick={handleForgotPasswordClick}
            />
            <Button
                text='Login'
                classes="mt-3 mb-2"
                onClick={handleSubmit}
                isSubmitted={isSubmitted}
            />
            {/* <div
                className='d-flex justify-content-center flex-direction-row mt-2'
            >
                <Description
                    text='Not a memeber?'
                    classes="fs-7"
                />
                <Description
                    text='Sign up now'
                    classes="link fs-7 ps-1"
                    onClick={handleSignUpClick}
                />

            </div> */}

        </AccountLayout>
    )
}