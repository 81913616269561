import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";

function ProtectedRoute({ children }) {

    const { isLoggedIn } = useAppContext();

    return (
        !isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.LOGIN)} replace={true} /> : children
    );
}

export default ProtectedRoute;