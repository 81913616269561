import React from "react";
import { Button, Description, Input, Title } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { useNavigate } from "react-router-dom";


export default function ForgotPassword({ setPage }) {
    const navigate = useNavigate()

    const handleBackButtonClick = () => {
        navigate("/" + WEB_ROUTES.LOGIN)
    }

    const handleSubmit = () => {
        navigate("/" + WEB_ROUTES.LOGIN)
    }

    return (
        <AccountLayout
            hasBackButton={true}
            handleBackButton={handleBackButtonClick}
        >
            <Title text="Forgot Password" classes="mb-2" />
            <Description
                text="In order to reset your password you need to enter your registered phone number."
                classes="text-center fs-7"
            />
            <Input
                placeholder="Email Address"
                classes="mt-4 mb-5"
            />
            <Button
                text='Continue'
                classes="mt-3 mb-2"
                onClick={handleSubmit}
            />

        </AccountLayout>
    )
}