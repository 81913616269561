import React, { useContext, useEffect, useState } from "react";

import CryptoJS from 'crypto-js';
import _ from "lodash";

import FullPageLoader from "../Components/Loader/FullPageLoader";
import { sessionStorageToken } from "../Helper";


const AppContext = React.createContext();

export function useAppContext() {
    return useContext(AppContext);
}


export default function Context({ children }) {
    // const session = useSessionStorage()
    const [session, setSession] = useState({
        isLoading: true,
        user: {}
    })

    useEffect(() => {
        const token = sessionStorage.getItem(sessionStorageToken());
        if (token) {
            const decrypted = CryptoJS.AES.decrypt(token, process.env.REACT_APP_SECRET_KEY);
            const data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            setSession(prev => {
                return {
                    isLoading: false,
                    user: data
                }
            })
        }
        else {
            setSession(prev => {
                return {
                    isLoading: false,
                    user: {}
                }
            })
        }
    }, [])


    const updateUser = (data = {}) => {
        sessionStorage.setItem(
            sessionStorageToken(),
            CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECRET_KEY)
        )
        setSession(prev => {
            return {
                ...prev,
                user: data
            }
        })
    }

    const logout = (data = {}) => {
        sessionStorage.removeItem(sessionStorageToken())
        setSession(prev => {
            return {
                ...prev,
                user: {}
            }
        })
    }

    return <AppContext.Provider
        value={{
            isLoggedIn: !_.isEmpty(session?.user),
            user: session?.user,
            updateUser,
            logout
        }}
    >
        {session?.isLoading ? <FullPageLoader /> : children}
    </AppContext.Provider>
}