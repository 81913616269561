import React, { useMemo } from "react";
import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes
} from 'react-router-dom';


import PublicRoute from "./Components/Routes/PublicRoute";
import { WEB_ROUTES } from "./constants";
import { baseRoute } from "./Helper";

import ProtectedLayout from "./Components/Layouts/ProtectedLayout";
import ProtectedRoute from "./Components/Routes/ProtectedRoute";

import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ProjectManagerManagement from "./Pages/ProjectManagerManagement";
import AccountManagerManagement from "./Pages/AccountManagerManagement";
import Lookups from "./Pages/Lookups";


function App() {

    const ROUTES = useMemo(() => {
        return [
            <Route exact path={baseRoute(WEB_ROUTES.LOGIN)} element={
                <PublicRoute >
                    <Login />
                </PublicRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.FORGOT_PASSWORD)} element={
                <PublicRoute >
                    <ForgotPassword />
                </PublicRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.OTP_VERIFICATION)} element={
                <PublicRoute >
                    <ForgotPassword />
                </PublicRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
                <ProtectedRoute>
                    <ProtectedLayout selectedRoute={0}>
                        <Dashboard />
                    </ProtectedLayout>
                </ProtectedRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.PM_MANAGEMENT)} element={
                <ProtectedRoute>
                    <ProtectedLayout selectedRoute={1}>
                        <ProjectManagerManagement />
                    </ProtectedLayout>
                </ProtectedRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.AM_MANAGEMENT)} element={
                <ProtectedRoute>
                    <ProtectedLayout selectedRoute={2}>
                        <AccountManagerManagement />
                    </ProtectedLayout>
                </ProtectedRoute>
            } />,
            <Route exact path={baseRoute(WEB_ROUTES.LOOKUPS)} element={
                <ProtectedRoute>
                    <ProtectedLayout selectedRoute={3}>
                        <Lookups />
                    </ProtectedLayout>
                </ProtectedRoute>
            } />,
        ]

    }, [])

    return (
        <Router>
            <Routes>
                {ROUTES.map((item) => item)}
                <Route path={baseRoute('*')} element={<Navigate to={baseRoute(WEB_ROUTES.LOGIN)} />} />
            </Routes >


        </Router >
    );
}

export default App;
