import React from 'react';

import styles from "./styles/AccountLayout.module.scss"

import { Container, Row, Col } from 'react-bootstrap';
import { IoIosArrowBack } from "react-icons/io";


import { ShadowContainer } from '../CustomComponents';

export default function AccountLayout({
    hasBackButton = false,
    handleBackButton = () => { },
    children,
}) {
    return (
        <div className={styles.AccountLayout}>
            <div className={styles.sectionContainer}>

                <Row className={styles.row}>
                    <Col
                        md={6}
                        className={`${styles.mediaContainer}`}
                    >
                        {/* <img src='/images/logo_assets.png' alt='Logo Assets' className={styles.image} /> */}
                        <img src='/images/graph.png' alt="Grapgh Image" className={`${styles.image} ${styles.logo}`} />
                    </Col>

                    <Col
                        md={6}
                        className={styles.formContainer}
                    >
                        <div className={styles.form}>
                            {hasBackButton ? <div className={styles.backButtonContainer} onClick={handleBackButton}>
                                <IoIosArrowBack />
                            </div> : ""
                            }
                            {children}
                        </div>
                    </Col>

                </Row>
            </div>
        </div>
    )
}