import React from "react";

import { AiOutlineSearch } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import styles from "./styles/Navbar.module.scss";



export default function Navbar({ isSearchBar = true, isSettingButton = false, handleToggle, handleNotificationModalOpen }) {

    // const handleFilterModalOpen = () => {
    //     dispatch(setFilterModal(true))
    // }

    return (
        <div className={styles.Navbar}>
            <div className={styles.titleContainer}>
                <span onClick={handleToggle} className={styles.toggleButton}><GiHamburgerMenu /></span>
                {/* <h3 className={styles.title}>{title}</h3> */}
            </div>
            {isSearchBar ?
                <div className={styles.search}>
                    <div className={styles.searchButton}>
                        <AiOutlineSearch />
                    </div>
                    <input
                        type="text"
                        placeholder="Search here"
                    // value={search}
                    // onChange={(e) => dispatch(setValue(e.target.value))}
                    />
                </div>
                : ''}
            {isSettingButton ?
                <div
                    className={styles.notificationButton}
                //  onClick={handleFilterModalOpen}
                >
                    <LuSettings2 />
                </div>
                : ''}
            <div
                className={styles.notificationButton}
            // onClick={handleNotificationModalOpen}
            >
                <IoIosNotificationsOutline />
            </div>

        </div >
    )
}