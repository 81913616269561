import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';


/**Import Custom Component */
import Navbar from "./Navbar";

// Icons
import { MdDashboard } from "react-icons/md";
import { TbLogout2 } from "react-icons/tb";
import { useAppContext } from "../../Context/AppContext";
import { WEB_ROUTES } from "../../constants";

export default function ProtectedLayout({ isSearchBar, isSettingButton, selectedRoute, children }) {
    const { user, logout } = useAppContext();
    const [collapsed, setCollapsed] = useState(window.innerWidth > 991)


    const items = useMemo(() => {
        return [
            {
                label: "Dashboard",
                route: WEB_ROUTES.DASHBOARD,
                icon: <MdDashboard />,
            },
            {
                label: "Project Manager",
                route: WEB_ROUTES.PM_MANAGEMENT,
                icon: <MdDashboard />,
            },
            {
                label: "Account Manager",
                route: WEB_ROUTES.AM_MANAGEMENT,
                icon: <MdDashboard />,
            },
            {
                label: "Lookups",
                route: WEB_ROUTES.LOOKUPS,
                icon: <MdDashboard />,
            },

        ]
    }, [])

    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }

    const handleLogout = () => {
        logout();
        // queryClient.clear()
    }


    return (
        <div className={styles.ProtectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>
            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >

                <div className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                    <img
                        src={user.image_url}
                        alt="User Profile"
                        className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed}
                    />
                    <p
                        className={styles.name}
                    >
                        {user.name}
                    </p>
                    {/* {IS_PLAYER ? "" :
                        <button
                            className={`${styles.profileButton} ${(selectedRoute === 5) ? 'bg' : ''}`}
                            onClick={handleViewProfile}
                        >
                            View Profile
                        </button>
                    } */}
                </div>

                {/* <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> */}

                {items.map(({ icon, label, route }, index) => {
                    return <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/${route}`} className={`${styles.item} ${index === selectedRoute ? styles.active : ""}`} key={index}>
                        <span style={{ fontSize: '20px', display: 'flex' }}> {icon} </span>
                        {collapsed ? <p>{label}</p> : ''}

                    </Link>
                })}

                <Link
                    className={styles.item}
                    onClick={handleLogout}
                // onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())}
                >
                    {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
                    <span style={{ fontSize: '20px', display: 'flex' }}> <TbLogout2 /> </span>
                    {collapsed ? <p>Logout</p> : ''}
                </Link>

            </div>
            <div className={styles.routeContent}>
                <Navbar
                    isSearchBar={isSearchBar}
                    isSettingButton={isSettingButton}
                    handleToggle={handleToggle}
                // handleNotificationModalOpen={handleNotificationModalOpen}
                />
                <div className={styles.content}>
                    {/* {
                        notificationModal ?
                            <NotificationModal handleNotificationModalClose={handleNotificationModalClose} />
                            : ''
                    } */}

                    {children}
                </div>
            </div>
        </div >
    )


}
