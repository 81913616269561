import React from 'react'

import styles from "./index.module.scss"
import Table from '../../Components/Table'
import { Button } from '../../Components/CustomComponents'

export default function ProjectManagerManagement() {
    const header = [
        {
            title: "Manager Name",
            dataIndex: "name",
            style: {
                width: "70%",
            }
        },
        {
            title: "Actions",
            style: {
                textAlign: "center"
            },
            type: "actions",
            render: (data, index, records) => {
                return <div className="w-100 h-100 d-flex flex-row align-items-center justify-content-center gap-3">
                    <p className="m-0 cursor-pointer">Edit</p>
                    <p className="m-0 cursor-pointer">Delete </p>
                </div>
            },

        },
    ]
    const data = [
        { name: "test" },
        { name: "test" },
        { name: "test" },
    ]

    return (
        <div className={styles.ProjectManagerManagement}>
            <div className='w-100 mb-2 d-flex justify-content-end'>
                <Button style={{ width: "100px", marginLeft: "auto" }} text='Add' />
            </div>
            <Table header={header} data={data} />
        </div>
    )
}
