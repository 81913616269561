import _ from "lodash";
import React from "react";
import { Navigate } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { baseRoute } from "../../Helper";
import { WEB_ROUTES } from "../../constants";


export default function PublicRoute({ children }) {
    const { isLoggedIn } = useAppContext();

    return (
        _.isUndefined(isLoggedIn) ? <></> :
            isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace={true} /> : children

    );
}
